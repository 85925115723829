<template>
    <div
        v-show="containsMessages"
        :id="`messenger_conversation_block_${id}`"
        class="conversation-block"
    >
        <chat-divider
            :date="dateCreated"
        />
        <template
            v-for="(message, index) of messages"
        >
            <chat-message
                :id="message.id"
                :key="String(id) + index"
                :type="message.type"
                :message="message.content"
                :messenger="messenger"
                :chat-id="chatId"
                :chat-customer-id="chatCustomerId"
                :date="message.createdAt"
                :author="message.author"
                :phone="message.phone"
                :status="message.status"
                :ticket="message.ticket"
                :location="message.location"
                :attachments="message.attachments"
                :sender-type="senderType"
            >
                <template #mass-check-element>
                    <transition name="fade">
                        <div
                            v-if="massSelectionActive"
                        >
                            <input
                                :checked="!!selectedMessages[message.id]"
                                type="checkbox"
                                @change="selectCheckboxHandler($event.target.checked, message.id)"
                            >
                        </div>
                    </transition>
                </template>
                <template #message-location-element="{ location }">
                    <div
                        v-if="location.lat && location.lng"
                        class="message-content pb-0"
                    >
                        <div class="d-flex align-items-center flex-wrap">
                            <span class="me-4">{{ $t('messengers', 'Location: ') }} </span>
                            <a
                                href="javascript:void(0)"
                                @click="$emit('show-message-location', location)"
                            >{{ location.address ? location.address : `${location.lat}, ${location.lng}` }}</a>
                        </div>
                    </div>
                </template>
            </chat-message>
            <chat-message-activities
                :key="String(message.id) + index"
                :activities="message.activities"
                :date-closed="dateClosed"
                :is-last-message="message.id === messages[messages.length - 1].id"
            />
        </template>
    </div>
</template>

<script>
import ChatMessage from '@/apps/admin/views/admin/common/communication/messengers/ChatMessage';
import { empty } from '@/utils/functions';
import ChatMessageActivities from '@/apps/admin/views/admin/common/communication/messengers/ChatMessageActivities';
import ChatDivider from '@/apps/admin/views/admin/common/communication/messengers/ChatDivider';

export default {
    name: 'ChatConversation',
    props: {
        id: [String, Number],
        messages: Array,
        chatId: [String, Number],
        chatCustomerId: [String, Number],
        massSelectionActive: {
            type: Boolean,
            default: false,
        },
        massSelectedIds: Array,
        messenger: [String, Number],
        dateCreated: String,
        dateClosed: String,
        senderType: String,
    },
    methods: {
        empty,
        selectCheckboxHandler(value, id) {
            if (value) {
                this.$emit('mass-message-select', id);
            } else {
                this.$emit('mass-message-deselect', id);
            }
        },
    },
    computed: {
        selectedMessages() {
            return this.massSelectedIds.reduce((accumulator, id) => {
                accumulator[id] = true;
                return accumulator;
            }, {});
        },
        containsMessages() {
            return this.messages.length;
        },
    },
    components: { ChatDivider, ChatMessageActivities, ChatMessage },
};
</script>
