<template>
    <div class="head-wrapper d-flex px-8 py-12 align-items-center justify-content-between">
        <a
            class="chatbox-back me-4"
            href="javascript:void(0)"
            @click="$emit('back')"
        >
            <i class="icon-ic_fluent_arrow_left_24_regular" />
        </a>
        <div
            class="chat-box-item-icon me-8"
        >
            <img
                :src="`/images/messengers/chat-box/${messengerName}.svg`"
                :alt="messengerFirstLetter"
            >
        </div>
        <div class="d-flex flex-column justify-content-center flex-grow-1">
            <a
                href="javascript:void(0)"
                :class="['chat-box-item-name', { disabled: !profileName }]"
                @click="openCustomer"
            >
                {{ profileName }} {{ profileName ? `(${phone})` : phone }}
            </a>
            <div
                v-if="assigned"
                class="chat-box-item-assignee mt-2"
            >
                {{ $t('messengers', 'Assigned to') }}
                <span>{{ assigned }}</span>
            </div>
        </div>
        <div
            v-if="showActions"
            class="ms-4 me-4"
        >
            <a
                class="chat-box-action"
                href="javascript:void(0)"
                :title="$t('messengers', 'Actions')"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <i class="icon-ic_fluent_more_vertical_24_regular" />
            </a>
            <ul
                class="dropdown-menu"
            >
                <li
                    class="dropdown-item"
                >
                    <a
                        href="javascript:void(0)"
                        data-test-selector="mass-action-link"
                        @click="$emit('set-mass-action-select-enabled', !massActionSelectEnabled)"
                    >
                        {{
                            massActionSelectEnabled ? $t('messengers', 'Deselect messages') : $t('messengers', 'Select messages')
                        }}
                    </a>
                </li>
                <li
                    v-for="action of actions"
                    :key="action.name"
                    class="dropdown-item"
                >
                    <a
                        href="javascript:void(0)"
                        data-submit="true"
                        data-activities="hide"
                        @click="$emit(action.name)"
                    >
                        {{ action.title }}{{ action.selectedCount }}
                    </a>
                </li>
            </ul>
        </div>
        <a
            class="chat-box-action"
            href="javascript:void(0)"
            :title="$t('messengers', 'Minimize')"
            @click="$closeChatBox()"
        >
            <i class="icon-ic_fluent_line_horizontal_1_20_regular" />
        </a>
    </div>
</template>

<script>
import HeadMassActionsMixin from '@/apps/admin/mixins/messengers/head/head-mass-actions-mixin';

export default {
    name: 'ChatHeadChatbox',
    mixins: [HeadMassActionsMixin],
    props: {
        messengerName: String,
        customerId: [Number, String],
        title: {
            type: String,
            default: '',
        },
        phone: {
            type: String,
            default: '',
        },
        assigned: {
            type: String,
            default: '',
        },
        profileName: {
            type: String,
            default: '',
        },
        showActions: Boolean,
        isLead: Boolean,
        actions: Array,
    },
    methods: {
        openCustomer() {
            if (!this.customerId) {
                return false;
            }
            if (this.isLead) {
                switch_page(`/admin/crm/leads/view?id=${this.customerId}`);
                return;
            }
            switch_page(`/admin/customers/view?id=${this.customerId}`);
        },
    },
    computed: {
        messengerFirstLetter() {
            return this.messengerName.at(0)
                .toUpperCase();
        },
    },
};
</script>
