<template>
    <div class="content active">
        <x-preloader-block :loading="loading">
            <div class="splynx-top-nav">
                <x-breadcrumbs :items="[...$route.meta.breadcrumbs, breadcrumbItem]" />
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <x-addon-code
                        ref="addon_code_before"
                        page="controllers\\admin\\networking\\monitoring\\ViewController"
                        location="before_content"
                        :params="{hardware: model}"
                    />
                </div>
            </div>
            <x-tabs
                id="hardware_view_tabs"
                ref="tabs"
                direction="horizontal"
            >
                <x-tab
                    id="information"
                    :title="$t('networking', 'Information')"
                >
                    <x-tab-ajax :url="`/admin/networking/monitoring/view--index?id=${model.id}`" />
                </x-tab>
                <x-tab
                    id="oid"
                    :title="$t('networking', 'SNMP OID')"
                >
                    <x-tab-ajax :url="`/admin/networking/monitoring/view--oid?id=${model.id}`" />
                </x-tab>
                <x-tab
                    id="logs"
                    :title="$t('networking', 'Logs')"
                >
                    <x-tab-ajax :url="`/admin/networking/monitoring/view--log?id=${model.id}`" />
                </x-tab>
                <x-tab
                    v-if="showGraphsTab"
                    id="graphs"
                    :title="$t('networking', 'Graphs')"
                >
                    <x-tab-ajax :url="`/admin/networking/monitoring/graphs-manage?id=${model.id}`" />
                </x-tab>
                <x-tab
                    id="backup"
                    :title="$t('networking', 'Backup')"
                >
                    <x-tab-ajax :url="`/admin/networking/monitoring/view--backup?id=${model.id}`" />
                </x-tab>
                <x-tab
                    id="map"
                    :title="$t('networking', 'Map')"
                >
                    <x-tab-ajax :url="`/admin/networking/monitoring/view--map?id=${model.id}`" />
                </x-tab>
                <x-tab
                    v-if="model.access_device"
                    id="customer_services"
                    :title="$t('networking', 'Customer services')"
                    :component="hardwareServicesTab"
                    :component-props="{model: model}"
                />
            </x-tabs>

            <div class="row">
                <div class="col-lg-12">
                    <x-addon-code
                        ref="addon_code_after"
                        page="controllers\\admin\\networking\\monitoring\\ViewController"
                        location="after_content"
                        :params="{hardware: model}"
                    />
                </div>
            </div>
        </x-preloader-block>
    </div>
</template>

<script>
import XBreadcrumbs from '@/components/base/XBreadcrumbs/XBreadcrumbs';
import XTabs from '@/components/common/XTabs/XTabs.vue';
import XTabAjax from '@/components/common/XTabs/XTabAjax.vue';
import { XTab } from '@/components/common/XTabs';
import HardwareService from '@/apps/admin/services/networking/HardwareService';
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock.vue';
import { XAddonCode } from '@/components/base/XAddonCode';

export default {
    name: 'HardwareView',
    data() {
        return {
            loading: true,
            model: {},
            showGraphsTab: false,
        };
    },
    mounted() {
        this.loadHardware();
    },
    methods: {
        async loadHardware() {
            await HardwareService.getPageDataForHardwareView(this.$route.query.id)
                .then((response) => {
                    this.model = response.model;
                    this.showGraphsTab = response.admin_can_view_graphs;
                }).then(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        breadcrumbItem() {
            let ip = this.model.ip ?? '';

            return {
                title: this.model.title + ip,
                url: '#',
            };
        },
        hardwareServicesTab() {
            return () => import('./HardwareViewCustomerServices');
        },
    },
    components: {
        XAddonCode,
        XPreloaderBlock,
        XTab,
        XTabAjax,
        XTabs,
        XBreadcrumbs,
    },
};
</script>
