import { promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - model
 *   - partner_id
 *   - assign_to
 */
export default {
    data() {
        return {
            mentionsList: [],
        };
    },
    methods: {
        /** @method Call it after loadData */
        __mentionsMixin_loadData() {
            this.getTaskMentions();
        },
        getTaskMentions() {
            promisifiedAjax({
                url: '/admin/scheduling/tasks--get-mentions',
                method: 'GET',
                data: {
                    partner_id: this.model.partner_id,
                    assign_to: this.model.assign_to,
                },
            }).then((response) => {
                this.mentionsList = response;
            });
        },
    },
};
