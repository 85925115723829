<template>
    <div class="chat-message-activities mt-8 mb-8">
        <div
            v-for="(activity, index) of preparedSortedActivities"
            :key="index + 'activity'"
        >
            <div v-html="activity.content" />
        </div>
        <hr v-if="resolvedExists">
    </div>
</template>

<script>
export default {
    name: 'ChatMessageActivities',
    props: {
        activities: Array,
        dateClosed: String,
        isLastMessage: Boolean,
    },
    computed: {
        resolvedExists() {
            return this.preparedSortedActivities.some((activity) => activity.resolved);
        },
        preparedSortedActivities() {
            const resolvedText = this.$t('messengers', 'This conversation has been resolved');
            if (!this.dateClosed && (!this.activities || this.activities.length === 0)) {
                return [];
            }
            if (!this.dateClosed) {
                return this.activities;
            }
            if (!this.isLastMessage) {
                return this.activities;
            }
            if (!this.activities.length) {
                return [{
                    resolved: true,
                    created_at: this.dateClosed,
                    content: resolvedText,
                }];
            }
            let sortedActivities = [...this.activities];
            const newActivity = {
                created_at: this.dateClosed,
                content: resolvedText,
                resolved: true,
            };
            moment.suppressDeprecationWarnings = true;
            const newActivityTime = moment(this.dateClosed).valueOf();
            let inserted = false;
            sortedActivities.forEach((activity, index) => {
                const activityTime = moment(activity.created_at).valueOf();
                if (!inserted && newActivityTime < activityTime) {
                    sortedActivities.splice(index, 0, newActivity);
                    inserted = true;
                }
            });
            moment.suppressDeprecationWarnings = false;
            if (!inserted) {
                sortedActivities.push(newActivity);
            }
            return sortedActivities;
        },
    },
};
</script>
