import TicketsService from '@/apps/admin/services/tickets/TicketsService';
import Message from '@/apps/admin/services/messengers/Message';

export default {
    methods: {
        onActionPerform({ name, data }) {
            this[window.toCamelCase(`action-${name}`)]?.call(this, data);
        },
        actionCreateTicket({
            messageId, customerId, messenger, chatId,
        }) {
            window.showModal('messenger-create-ticket-from-message', {
                customerId,
                messenger,
                chatId,
                message: new Message({ content: this.message }),
                onSubmit: async ({ ticketId }) => {
                    const service = new TicketsService(this.senderType);
                    const { result, message } = await service.linkMessageToTicket(messageId, ticketId);
                    if (!result) {
                        this.$showError(message, 4);
                        return false;
                    }
                    this.$showSuccess(message, 4);
                },
            });
        },
        async actionLinkTicket({ ticket }) {
            window.showModal('messenger-link-ticket-to-message', {
                linked: ticket,
                customerId: this.chatCustomerId,
                messageId: this.id,
                senderType: this.senderType,
                chatId: this.chatId,
                unlinkCallback: (args) => this.actionUnlinkTicket(args),
            });
        },
        async actionUnlinkTicket({ messageId, ticketId }) {
            window.vue_confirm_dialog(
                this.$t('config', 'Are you sure you want unlink ticket #{ticketId}?', { ticketId }),
                async () => {
                    const service = new TicketsService(this.senderType);
                    const { result, message } = await service.unlinkMessageFromTicket(messageId, ticketId);
                    if (!result) {
                        this.$showError(message, 4);
                        return false;
                    }
                    this.$showSuccess(message, 4);
                },
                () => false,
            );
        },
    },
};
