export default class Attachment {
    constructor(data = {}) {
        this.isPws = data['data-is-pws'];
        this.downloadUrl = data['data-pws-download-url'];
        this.ratio = data['data-pws-ratio'];
        this.title = data['data-pws-title'];
        this.type = data['data-pws-type'];
        this.url = data['data-pws-url'];

        this.name = data.name;
        this.id = data.id;
        this.size = data.size;
        this.link = data.link;
        this.isDownloadAllUrl = data.is_download_all_url;
        this.downloadAllUrl = data.download_all_url;
    }
}
