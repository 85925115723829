import {
    ON_BOARDING_CURRENT_STEP,
    ON_BOARDING_LOAD,
    ON_BOARDING_MAIN_DASHBOARD_LOAD,
    ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD,
    ON_BOARDING_REFS,
    ON_BOARDING_SCHEDULING_LOAD,
    ON_BOARDING_TASK_VIEW_LOAD,
    TASK_VIEW_ON_BOARDING_SHORT,
    ON_BOARDING_STEPS,
    SET_APP_CHILD_REFERENCES,
    ON_BOARDING_NETWORK_SITE_VIEW_LOAD,
    ON_BOARDING_NETWORK_SITES_LIST_LOAD,
    SET_ON_BOARDING_PERMISSIONS,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { empty, promisifiedAjax } from '@/utils/functions';

const state = {
    modalShow: false,
    appRefs: {},
    onBoardingStepsToGo: [],
    pageBillingConfigLoad: false,
    pageFinanceDocumentsLoad: false,
    pageMainDashboardLoad: false,
    pageSchedulingLoad: false,
    pageTaskViewLoad: false,
    pageNetworkSiteViewLoad: false,
    pageNetworkSitesListLoad: false,
    taskViewOnBoardingShort: false,
    // form step
    /**
     * steps=[
     *  {
     *   selectedElements : [
     *        {
     *             element: clonedElement,
     *              offset: {
     *                 left: 0,
     *                 right: 0,
     *                 width: 0,
     *                 height: 0
     *              },
     *          }
     *      ],
     *   message:{
     *            title: '',
     *             text: '',
     *             buttonAction: fn() - example for close modal
     *   },
     *   callback: function(){
     *                  functions for next steps
     *   }
     *  }
     * ]
     *
     */
    currentStep: {},
    showDeploymentGuideButton: false,
    currentOnBoarding: '',
    rememberedStep: '',
    permissionsMap: {},
};

const actions = {
    modalShow({ commit }, modalShow) {
        commit(ON_BOARDING_LOAD, modalShow);
    },
    pageFinanceDocumentsLoadChange({ commit }, pageFinanceDocumentsLoad) {
        commit(ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD, pageFinanceDocumentsLoad);
    },
    pageMainDashboardLoadChange({ commit }, pageMainDashboardLoad) {
        commit(ON_BOARDING_MAIN_DASHBOARD_LOAD, pageMainDashboardLoad);
    },
    pageSchedulingLoadChange({ commit }, pageSchedulingLoad) {
        commit(ON_BOARDING_SCHEDULING_LOAD, pageSchedulingLoad);
    },
    pageTaskViewLoadChange({ commit }, pageTaskViewLoad) {
        commit(ON_BOARDING_TASK_VIEW_LOAD, pageTaskViewLoad);
    },
    pageNetworkSiteViewLoadChange({ commit }, networkSiteViewLoad) {
        commit(ON_BOARDING_NETWORK_SITE_VIEW_LOAD, networkSiteViewLoad);
    },
    pageNetworkSitesListLoadChange({ commit }, pageNetworkSitesListLoad) {
        commit(ON_BOARDING_NETWORK_SITES_LIST_LOAD, pageNetworkSitesListLoad);
    },
    taskViewOnBoardingShort({ commit }, taskViewOnBoardingShort) {
        commit(TASK_VIEW_ON_BOARDING_SHORT, taskViewOnBoardingShort);
    },
    addRefs({ commit, dispatch }, refs) {
        let clonedRefs = { ...refs };

        commit(ON_BOARDING_REFS, clonedRefs);
        commit(SET_APP_CHILD_REFERENCES, clonedRefs, { root: true });
        dispatch('addSteps');
        dispatch('checkWhatOnBoardingToLoad');
    },
    addSteps() {
        if (empty(state.onBoardingStepsToGo)) {
            state.onBoardingStepsToGo = this.getters.onBoardingSteps;
        }
    },
    checkWhatOnBoardingToLoad({ state, dispatch, commit }) {
        let count = 0;
        let timer = setInterval(() => {
            count++;
            if (count === 5) {
                clearInterval(timer);
                return;
            }

            if (state.pageFinanceDocumentsLoad && state.onBoardingStepsToGo.indexOf('billingModuleOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'billingModuleOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageMainDashboardLoad && state.onBoardingStepsToGo.indexOf('regularUserOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'regularUserOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageMainDashboardLoad && state.onBoardingStepsToGo.indexOf('superAdminOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'superAdminOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageSchedulingLoad && state.onBoardingStepsToGo.indexOf('schedulingOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'schedulingOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageTaskViewLoad && state.onBoardingStepsToGo.indexOf('taskViewOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'taskViewOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageNetworkSiteViewLoad && state.onBoardingStepsToGo.indexOf('networkSiteViewGuideOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'networkSiteViewGuideOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageNetworkSitesListLoad && state.onBoardingStepsToGo.indexOf('networkSitesListGuideOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'networkSitesListGuideOnBoarding');
                dispatch('initOnBoarding');
            }
        }, 500);
    },
    removeRefs({ state }) {
        state.appRefs = {};
    },
    popstateHandle({ dispatch }) {
        window.onpopstate = () => {
            dispatch('closeOnBoarding');
        };
    },
    closeOnBoarding({ dispatch }) {
        dispatch('modalShow', false);
        dispatch('bodyScroll');
        dispatch('removeRefs');
    },
    initOnBoarding({ state, dispatch }) {
        if (window.innerWidth < 1024) {
            dispatch('modalShow', false);
            return;
        }
        if (state.currentOnBoarding !== '') {
            if (state.rememberedStep !== '') {
                dispatch(state.rememberedStep);
            } else {
                dispatch(state.currentOnBoarding);
            }
            dispatch('bodyScroll');

            window.addEventListener('resize', () => {
                if (state.currentOnBoarding !== '') {
                    if (window.innerWidth < 1024) {
                        dispatch('modalShow', false);
                        return;
                    }

                    if (state.rememberedStep !== '') {
                        dispatch(state.rememberedStep);
                    } else {
                        dispatch(state.currentOnBoarding);
                    }
                    dispatch('bodyScroll');
                }
            });

            dispatch('popstateHandle');
        }
    },
    billingModuleOnBoarding({ state, dispatch }) {
        let { financeDocumentsTab } = state.appRefs;
        if (typeof financeDocumentsTab !== 'undefined') {
            dispatch('billingModuleOnBoardingStepOne');
        }
    },
    billingModuleOnBoardingStepOne({ dispatch, commit }) {
        let message = {
            title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 1/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'New simple logic.'),
            text: window.xApp.$splang.t('common', 'We\'ve simplified the logic and use of billing in our product. Next, we\'ll show you how.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'billingModuleOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('billingModuleOnBoardingStepTwo');
                        commit('rememberedStep', 'billingModuleOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 324,
                width: 606,
                height: 168,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            elements: [],
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    billingModuleOnBoardingStepTwo({ dispatch, commit }) {
        let tabsCustomerBilling = document.querySelector('#customers_view_billing_tabs');
        let elements = [];
        let message = function () {
            if (typeof tabsCustomerBilling !== 'undefined') {
                elements.push(tabsCustomerBilling.querySelector('.x-tabs-bar'));
                let posElement2 = tabsCustomerBilling.querySelector('.x-tabs-bar').getBoundingClientRect();
                let leftPosMessage2 = posElement2.left - 10;
                let leftTopMessage2 = posElement2.top + posElement2.height + 10;
                return {
                    title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 2/4</span>'),
                    subtitle: window.xApp.$splang.t('common', '3 Tabs.'),
                    // eslint-disable-next-line
                    text: window.xApp.$splang.t('common', 'In the first tab, we have gathered all <b>Financial documents</b> for a more unified look and feel <b>Transactions</b> have been kept as it details all billing interactions. Billing overview has also been renamed to <b>Billing config</b>.'),
                    buttons: [
                        {
                            buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                            buttonClassNames: 'btn btn-cancel',
                            buttonAction() {
                                dispatch('completeTour', 'billingModuleOnBoarding');
                                commit('rememberedStep', '');
                            },
                        },
                        {
                            buttonText: window.xApp.$splang.t('common', 'Next'),
                            buttonClassNames: 'btn btn-primary',
                            buttonAction() {
                                dispatch('billingModuleOnBoardingStepThree');
                                commit('rememberedStep', 'billingModuleOnBoardingStepThree');
                            },
                        },
                    ],
                    offset: {
                        left: leftPosMessage2,
                        top: leftTopMessage2,
                        width: 532,
                        height: 208,
                    },
                };
            }
        };
        let callback = function () {
        };
        let step = {
            elements,
            message: message(),
            callback,
        };
        dispatch('generateStep', step);
    },
    billingModuleOnBoardingStepThree({ dispatch, commit }) {
        let elements = [];
        let message = function () {
            let tabsCustomerBillingOverviewBlock = document.querySelector('#tabsCustomerBillingOverviewBlock');
            if (typeof tabsCustomerBillingOverviewBlock !== 'undefined') {
                elements.push(tabsCustomerBillingOverviewBlock);
                let posElement3 = tabsCustomerBillingOverviewBlock.getBoundingClientRect();
                let leftPosMessage3 = posElement3.left - 10;
                let leftTopMessage3 = posElement3.top + posElement3.height + 20;
                return {
                    title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 3/4</span>'),
                    subtitle: window.xApp.$splang.t('common', 'Customer billing overview.'),
                    text: window.xApp.$splang.t('common', 'Always visible and easily accessible so you don\'t have to switch between tabs.'),
                    buttons: [
                        {
                            buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                            buttonClassNames: 'btn btn-cancel',
                            buttonAction() {
                                dispatch('completeTour', 'billingModuleOnBoarding');
                                commit('rememberedStep', '');
                            },
                        },
                        {
                            buttonText: window.xApp.$splang.t('common', 'Next'),
                            buttonClassNames: 'btn btn-primary',
                            buttonAction() {
                                dispatch('billingModuleOnBoardingStepFour');
                                commit('rememberedStep', 'billingModuleOnBoardingStepFour');
                            },
                        },
                    ],
                    offset: {
                        left: leftPosMessage3,
                        top: leftTopMessage3,
                        width: tabsCustomerBillingOverviewBlock.width,
                        height: 188,
                    },
                };
            }
        };
        let callback = function () {
        };
        let step = {
            elements,
            message: message(),
            callback,
        };
        dispatch('generateStep', step);
    },
    billingModuleOnBoardingStepFour({ state, dispatch, commit }) {
        let elements = [];
        let message = function () {
            let { billingDataTableWrapper } = state.appRefs;
            if (typeof billingDataTableWrapper !== 'undefined') {
                let table = document.querySelector('#admin_customers_view_finance_documents_list');
                let tableCells = table.querySelectorAll('th:nth-child(2), td:nth-child(2)');
                let button = document.querySelector('#addDocumentButtonHolder');
                let buttonOffset = button.getBoundingClientRect();
                elements.push(button, tableCells);
                return {
                    title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 4/4</span>'),
                    subtitle: window.xApp.$splang.t('common', 'All Financial documents in one place.'),
                    // eslint-disable-next-line
                    text: window.xApp.$splang.t('common', 'We have merged all financial documents into one space - they are now easily accessible and can also be filtered to view certain types of documents. We have also introduced future items, credit notes and partial payments from version 4.0'),
                    buttons: [
                        {
                            buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                            buttonClassNames: 'btn btn-primary',
                            buttonAction() {
                                dispatch('completeTour', 'billingModuleOnBoarding');
                                commit('rememberedStep', '');
                            },
                        },
                    ],
                    offset: {
                        left: 582,
                        top: buttonOffset.top + buttonOffset.height + 20,
                        width: 493,
                        height: 228,
                    },
                };
            }
        };

        let callback = function () {
        };
        let step = {
            elements,
            message: message(),
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoarding({ state, dispatch }) {
        let { dashboardReff } = state.appRefs;
        if (typeof dashboardReff !== 'undefined') {
            dispatch('regularUserOnBoardingStepOne');
        }
    },
    regularUserOnBoardingStepOne({ state, dispatch, commit }) {
        let { menuHolder } = state.appRefs;
        let elements = [menuHolder];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 1/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Main menu.'),
            text: window.xApp.$splang.t('common', 'Here you can find all the available functions.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('regularUserOnBoardingStepTwo');
                        commit('rememberedStep', 'regularUserOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                left: menuHolder.getBoundingClientRect().width + 30,
                top: 220,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoardingStepTwo({ state, dispatch, commit }) {
        let { headerNavigationHolder } = state.appRefs;
        let elements = [headerNavigationHolder];
        let left = headerNavigationHolder.getBoundingClientRect().width < 372 ? window.innerWidth - 372 : headerNavigationHolder.getBoundingClientRect().left - 10;
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 2/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Quick access.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'We\'ve brought frequent and important features to a visible place where you can always ask for help,  use our powerful search tool, receive notifications or browse product documentation.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('regularUserOnBoardingStepThree');
                        commit('rememberedStep', 'regularUserOnBoardingStepThree');
                    },
                },
            ],
            offset: {
                left,
                top: headerNavigationHolder.getBoundingClientRect().height + 30,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoardingStepThree({ state, dispatch, commit }) {
        let { dashboardReff } = state.appRefs;
        let elements = [dashboardReff];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 3/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'The dashboard.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'Here we have collected information for you from the main modules of the product which is worth paying attention to. Some modules also have their own dashboards.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('regularUserOnBoardingStepFour');
                        commit('rememberedStep', 'regularUserOnBoardingStepFour');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 225,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoardingStepFour({ dispatch, commit }) {
        let elements = [];
        let text1 = window.xApp.$splang.t('common', 'You can explore the product yourself.');
        let text2 = window.xApp.$splang.t('common', 'Or');
        let text3 = window.xApp.$splang.t('common', 'watch the video');
        let text4 = window.xApp.$splang.t('common', 'that our team has recorded for you, which clarifies many aspects of Splynx product.');
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 4/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Where to begin?'),
            text: `${text1}</br>${text2}&nbsp;<a href="https://www.youtube.com/watch?v=UtE4oifGqZ8" target="_blank">${text3}</a>&nbsp;${text4}`,
            video: 'https://www.youtube.com/embed/UtE4oifGqZ8',
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 60,
                width: 446,
                height: 456,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoarding({ state, dispatch }) {
        let { dashboardReff } = state.appRefs;
        if (typeof dashboardReff !== 'undefined') {
            dispatch('superAdminOnBoardingStepOne');
        }
    },
    superAdminOnBoardingStepOne({ state, dispatch, commit }) {
        let { menuHolder } = state.appRefs;
        let elements = [menuHolder];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 1/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Main menu.'),
            text: `${window.xApp.$splang.t('common', 'Lots of items, right?')}<br>${
                window.xApp.$splang.t('common', 'You can hide the ones your business does\'t need')}&nbsp;`
                + `<a href="/admin/config/modules/main" target="_blank">${window.xApp.$splang.t('common', 'on the Config page')}</a>`,
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('superAdminOnBoardingStepTwo');
                        commit('rememberedStep', 'superAdminOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                left: menuHolder.getBoundingClientRect().width + 30,
                top: 220,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoardingStepTwo({ state, dispatch, commit }) {
        let { headerNavigationHolder } = state.appRefs;
        let elements = [headerNavigationHolder];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 2/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Quick access.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'We\'ve brought frequent and important features to a visible place where you can always ask for help, use our powerful search tool, receive notifications or browse product documentation.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('superAdminOnBoardingStepThree');
                        commit('rememberedStep', 'superAdminOnBoardingStepThree');
                    },
                },
            ],
            offset: {
                left: headerNavigationHolder.getBoundingClientRect().left - 10,
                top: headerNavigationHolder.getBoundingClientRect().height + 30,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoardingStepThree({ state, dispatch, commit }) {
        let { dashboardReff } = state.appRefs;
        let elements = [dashboardReff];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 3/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'The dashboard.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'Here we have collected information for you from the main modules of the product, which is worth paying attention to.<br> Some modules also have their own dashboards.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('superAdminOnBoardingStepFour');
                        commit('rememberedStep', 'superAdminOnBoardingStepFour');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 225,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoardingStepFour({ dispatch, commit }) {
        let elements = [];
        let text1 = window.xApp.$splang.t('common', 'Use deployment guide');
        let text2 = window.xApp.$splang.t('common', 'Or');
        let text3 = window.xApp.$splang.t('common', 'watch the video');
        let text4 = window.xApp.$splang.t('common', 'that our team has recorded for you, which clarifies many aspects of Splynx product deployment.');
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 4/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Where to begin?'),
            text: `<span class="styled-link" onclick="document.getElementById('buttonDeploymentGuide').click();">${text1}.</span>&nbsp;${text2}&nbsp;`
                + `<a href="https://www.youtube.com/watch?v=UtE4oifGqZ8" target="_blank">${text3}</a>&nbsp;${text4}`,
            video: 'https://www.youtube.com/embed/UtE4oifGqZ8',
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 60,
                width: 446,
                height: 456,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    schedulingOnBoarding({ state, dispatch }) {
        let { schedulingCalendar } = state.appRefs;
        if (typeof schedulingCalendar !== 'undefined') {
            dispatch('schedulingOnBoardingStepOne');
        }
    },
    schedulingOnBoardingStepOne({ dispatch, commit }) {
        let message = {
            title: t('common', 'Scheduling calendar tour. <span>Step 1/4</span>'),
            subtitle: `${t('common', 'Welcome to the revamped calendar view!')}<br>`,
            text: `${t('common', 'Experience more options and enjoy the convenience of editing tasks')}<br>${
                t('common', 'on a single page.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'schedulingOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('schedulingOnBoardingStepTwo');
                        commit('rememberedStep', 'schedulingOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                width: 502,
                height: 188,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    schedulingOnBoardingStepTwo({ dispatch, commit }) {
        let toggleButton = document.querySelector('#toggleButton');
        let elements = [toggleButton];
        let elementOffset = toggleButton.getBoundingClientRect();
        let message = {
            title: t('common', 'Scheduling calendar tour. <span>Step 2/4</span>'),
            subtitle: `${t('common', 'Teams & Assignees.')}<br>`,
            text: `${t('common', 'Effortlessly view all the teams and assignees you are interested in on a')}<br>${
                t('common', 'single screen. Simply reveal their details by clicking on this icon.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'schedulingOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('schedulingOnBoardingStepThree');
                        commit('rememberedStep', 'schedulingOnBoardingStepThree');
                    },
                },
            ],
            offset: {
                left: elementOffset.left - 15,
                top: elementOffset.top + elementOffset.height + 20,
                width: 532,
                height: 188,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    schedulingOnBoardingStepThree({ dispatch, commit }) {
        let schedulingNavigationButtons = document.querySelector('#schedulingNavigationButtons');
        let elements = [schedulingNavigationButtons];
        let elementOffset = schedulingNavigationButtons.getBoundingClientRect();
        let message = {
            title: t('common', 'Scheduling calendar tour. <span>Step 3/4</span>'),
            subtitle: `${t('common', 'Backlog & Filters.')}<br>`,
            text: `${t('common', 'We\'ve introduced sorting and search functionality to the ')}<b>${t('common', 'Backlog,')}</b><br>${
                t('common', 'allowing you to easily organize and find tasks by project, assignee, and')}<br>
                ${t('common', 'creator. Additionally, ')}<b>${t('common', 'Filters')}</b>
                ${t('common', 'now offer more options for a more flexible')}<br>
                ${t('common', 'display of information.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'schedulingOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('schedulingOnBoardingStepFourth');
                        commit('rememberedStep', 'schedulingOnBoardingStepFourth');
                    },
                },
            ],
            offset: {
                left: elementOffset.right - 532,
                top: elementOffset.top + elementOffset.height + 20,
                width: 532,
                height: 228,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    schedulingOnBoardingStepFourth({ dispatch, commit }) {
        let schedulingAddTaskButton = document.querySelector('#schedulingAddTaskButton');
        let elements = [schedulingAddTaskButton];
        let elementOffset = schedulingAddTaskButton.getBoundingClientRect();
        let message = {
            title: t('common', 'Scheduling calendar tour. <span>Step 4/4</span>'),
            subtitle: `${t('common', 'Task creation.')}<br>`,
            text: `${t('common', 'Experience seamless task creation directly from the sidebar while')}<br>
                ${t('common', 'staying on the same page. If needed, you can always navigate to the')}<br>
                ${t('common', 'Task page later for more details.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'schedulingOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left: elementOffset.right - 385,
                top: elementOffset.top + elementOffset.height + 20,
                width: 532,
                height: 208,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    networkSitesListGuideOnBoarding({ state, dispatch }) {
        const { page } = state.appRefs;
        if (typeof page !== 'undefined') {
            dispatch('networkSitesListGuideOnBoardingStepOne');
        }
    },
    networkSitesListGuideOnBoardingStepOne({ dispatch, commit }) {
        const addButton = document.querySelector('#admin_networking_network_sites_add_button');
        const elements = [addButton];
        const config = {
            height: '52',
            width: '73',
            styles: 'with-tab-padding',
        };
        const step = {
            config,
            elements,
            message: {
                title: t('networking', 'Network sites tour.'),
                subtitle: t('networking', 'Welcome to the new network site module! {br}', { br: '<br />' }),
                // eslint-disable-next-line max-len,vue/max-len
                text: t('networking', 'This feature enables you to add an unlimited number of network sites, each seamlessly integrated with linked hardware devices, access points, and customer services. Add your first network site to explore its capabilities.'),
                offset: {
                    width: 502,
                    height: 228,
                    align: 'center',
                },
                buttons: [
                    {
                        buttonText: t('common', 'Close & discover the product'),
                        buttonClassNames: 'btn btn-primary',
                        buttonAction() {
                            dispatch('completeTour', 'networkSitesListGuideOnBoarding');
                            commit('rememberedStep', '');
                        },
                    },
                ],
            },
            callback: () => {},
        };
        dispatch('generateStep', step);
    },
    networkSiteViewGuideOnBoarding({ state, dispatch }) {
        const { tabs } = state.appRefs;
        if (typeof tabs !== 'undefined') {
            dispatch('networkSiteViewGuideOnBoardingStepOne');
        }
    },
    networkSiteViewGuideOnBoardingStepOne({ dispatch, commit }) {
        const tabsPanel = document.querySelector('#network_site_view_tabs .x-tabs-bar');
        const elements = [tabsPanel];
        const elementOffset = tabsPanel.getBoundingClientRect();
        const link = `<a href="https://wiki.splynx.com/en/networking/hardware#adding-a-new-device" target="_blank">${t('common', 'Access device toggle')}</a>`;

        const message = {
            title: t('networking', 'Network sites tour. {step}', { step: '<span>Step 1/3</span>' }),
            subtitle: t('networking', 'Easy manage {br}', { br: '<br />' }),
            text: t('networking', 'Easily switch between tabs to manage your network site efficiently. To link customer services to existing hardware, first enable the {link}.', { link }),
            offset: {
                left: elementOffset.left - 14,
                top: elementOffset.bottom + 16,
                width: 502,
                height: 208,
            },
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'networkSiteViewGuideOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('networkSiteViewGuideOnBoardingStepTwo');
                        commit('rememberedStep', 'networkSiteViewGuideOnBoardingStepTwo');
                    },
                },
            ],
        };

        const config = {
            height: '54',
            styles: 'with-tab-padding',
        };

        dispatch('generateStep', {
            elements,
            message,
            config,
            callback: () => {},
        });
    },
    networkSiteViewGuideOnBoardingStepTwo({ dispatch, commit }) {
        const tabsPanel = document.querySelector('#network_site_view_tabs #t-customers');
        const elements = [tabsPanel];
        const elementOffset = tabsPanel.getBoundingClientRect();
        const link = `<a href="/admin/customers/list" target="_blank">${t('networking', 'Customers list')}</a>`;

        const message = {
            title: t('networking', 'Network sites tour. {step}', { step: '<span>Step 2/3</span>' }),
            subtitle: t('networking', 'Streamlined Linking {br}', { br: '<br />' }),
            // eslint-disable-next-line max-len,vue/max-len
            text: t('networking', 'Here, you can see which customer services are linked to Access device. Customers services can be linked either individually or in bulk. To link multiple customer services, use the mass actions button in the top left corner of the {link} and click on “Link to access device.”', { link }),
            offset: {
                left: elementOffset.left,
                top: elementOffset.bottom + 16,
                width: 532,
                height: 228,
            },
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'networkSiteViewGuideOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('networkSiteViewGuideOnBoardingStepThree');
                        commit('rememberedStep', 'networkSiteViewGuideOnBoardingStepThree');
                    },
                },
            ],
        };

        const config = {
            height: '54',
            styles: 'with-tab-padding',
            parentClass: 'x-tabs-bar mode-light',
        };

        dispatch('generateStep', {
            elements,
            message,
            config,
            callback: () => {},
        });
    },
    networkSiteViewGuideOnBoardingStepThree({ dispatch, commit }) {
        const base = `<a href="https://wiki.splynx.com/en/networking/network-sites" target="_black">${t('networking', 'Splynx knowledge base')}</a>`;
        const step = {
            message: {
                title: t('networking', 'Network sites tour. {step}', { step: '<span>Step 3/3</span>' }),
                subtitle: t('networking', 'Get started with network sites {br}', { br: '<br />' }),
                text: t('networking', 'For comprehensive details on this feature, visit our {base}.', { base }),
                offset: {
                    width: 502,
                    height: 188,
                    align: 'center',
                },
                buttons: [
                    {
                        buttonText: t('common', 'Close & discover the product'),
                        buttonClassNames: 'btn btn-primary',
                        buttonAction() {
                            dispatch('completeTour', 'networkSiteViewGuideOnBoarding');
                            commit('rememberedStep', '');
                        },
                    },
                ],
            },
            callback: () => {},
        };
        dispatch('generateStep', step);
    },
    taskViewOnBoarding({ state, dispatch }) {
        let { taskView } = state.appRefs;
        if (typeof taskView !== 'undefined') {
            dispatch('taskViewOnBoardingStepOne');
        }
    },
    taskViewOnBoardingStepOne({ dispatch, commit }) {
        const stepTour = state.taskViewOnBoardingShort ? '<span>Step 1/3</span>' : '<span>Step 1/4</span>';
        let message = {
            title: t('common', 'Task page tour. {stepTour}', { stepTour }),
            subtitle: `${t('common', 'WELCOME TO THE REVAMPED TASKS PAGE.')}<br>`,
            text: `${t('common', 'We have arranged everything so that you can simultaneously manage')}<br>${
                t('common', 'both the task and the details of the corresponding customer.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'taskViewOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('taskViewOnBoardingStepTwo');
                        commit('rememberedStep', 'schedulingOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                width: 532,
                height: 188,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    taskViewOnBoardingStepTwo({ dispatch, commit }) {
        let taskDetailsCard = document.querySelector('#taskDetailsCard');
        let elements = [taskDetailsCard];
        let elementOffset = taskDetailsCard.getBoundingClientRect();
        const stepTour = state.taskViewOnBoardingShort ? '<span>Step 2/3</span>' : '<span>Step 2/4</span>';
        let message = {
            title: t('common', 'Task page tour. {stepTour}', { stepTour }),
            subtitle: `${t('common', 'TASK SECTIONS.')}<br>`,
            text: `${t('common', 'All necessary information on the task is collected here. Each tab has ')}<br>${
                t('common', 'several improvements.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'taskViewOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        let nextStep = state.taskViewOnBoardingShort ? 'taskViewOnBoardingStepFourth' : 'taskViewOnBoardingStepThree';
                        dispatch(nextStep);
                        commit('rememberedStep', nextStep);
                    },
                },
            ],
            offset: {
                left: elementOffset.left + 19,
                top: elementOffset.top + 127,
                width: 502,
                height: 188,
            },
        };

        let callback = function () {
        };

        let config = {
            height: '120',
            styles: 'with-tab-padding',
        };

        let step = {
            elements,
            message,
            config,
            callback,
        };
        dispatch('generateStep', step);
    },
    taskViewOnBoardingStepThree({ dispatch, commit }) {
        let customerDetailsCard = document.querySelector('#customerDetailsCard');
        let elements = [customerDetailsCard];
        let elementOffset = customerDetailsCard.getBoundingClientRect();
        let message = {
            title: t('common', 'Task page tour. <span>Step 3/4</span>'),
            subtitle: `${t('common', 'CUSTOMER INFORMATION.')}<br>`,
            text: `${t('common', 'Basic information about the customer and their service is always ')}<br>${
                t('common', 'available when you are working on a task. Link a customer to a task')}<br>${
                t('common', 'to see all the new features on the updated page.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'taskViewOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('taskViewOnBoardingStepFourth');
                        commit('rememberedStep', 'taskViewOnBoardingStepFourth');
                    },
                },
            ],
            offset: {
                left: elementOffset.left - 100,
                top: elementOffset.top + 112,
                width: 502,
                height: 208,
            },
        };

        let callback = function () {
        };

        let config = {
            height: '105',
            styles: 'with-tab-padding',
        };

        let step = {
            elements,
            message,
            config,
            callback,
        };
        dispatch('generateStep', step);
    },
    taskViewOnBoardingStepFourth({ dispatch, commit }) {
        let customerButtons = document.querySelector('.customer-buttons-wrapper');
        let elements = [customerButtons];
        let elementOffset = customerButtons.getBoundingClientRect();
        const stepTour = state.taskViewOnBoardingShort ? '<span>Step 3/3</span>' : '<span>Step 4/4</span>';
        let message = {
            title: t('common', 'Task page tour. {stepTour}', { stepTour }),
            subtitle: `${t('common', 'UPDATED MAIN CONTROLS.')}<br>`,
            text: `${t('common', 'Experience seamless task creation directly from the sidebar and an')}<br>${
                t('common', 'updated task-saving logic. Now, when switching between tabs, by')}<br>${
                t('common', 'default, but with a warning, the changes made in the task will')}${
                t('common', ' automatically be saved.')}`,
            buttons: [
                {
                    buttonText: t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'taskViewOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left: elementOffset.left - 347,
                top: elementOffset.top + 62,
                width: 502,
                height: 228,
            },
        };

        let callback = function () {
        };

        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    showDeploymentGuide() {
        if (state.onBoardingStepsToGo.indexOf('deploymentGuideEnabled') > -1) {
            state.showDeploymentGuideButton = true;
        }
    },
    hideDeploymentGuide({ state }) {
        promisifiedAjax({
            url: '/admin/profile--make-tour-as-complete',
            data: {
                tour: 'deploymentGuideEnabled',
            },
            method: 'POST',
        });
        state.showDeploymentGuideButton = false;
    },
    generateStep({ commit }, stepInfo) {
        let {
            elements, message, callback, config,
        } = stepInfo;
        let selectedElements = [];
        let scrollTopAnchor = 0;
        let clonedElement;
        let left;
        let top;
        let width;
        let height;
        let styles;
        let elementOffset;

        if (elements && elements.length > 0) {
            elements.forEach((element) => {
                if (Object.prototype.isPrototypeOf.call(NodeList.prototype, element)) {
                    let divWrap = document.createElement('table');
                    divWrap.classList = 'table table-striped table-without-vertical-margin';
                    element.forEach((item, index) => {
                        if (index === 0) {
                            elementOffset = item.getBoundingClientRect();
                        }
                        let row = document.createElement('tr');
                        row.appendChild(item.cloneNode(true));
                        divWrap.appendChild(row);
                    });
                    clonedElement = divWrap;
                } else {
                    elementOffset = element.getBoundingClientRect();
                    clonedElement = element.cloneNode(true);
                }

                left = parseInt(elementOffset.left) - 10;
                top = parseInt(elementOffset.top) - 8;
                width = config && config.width ? parseInt(config.width) : parseInt(elementOffset.width) + 20;
                if (config && config.height) {
                    height = parseInt(config.height);
                }
                if (config && config.styles) {
                    styles = config.styles;
                }

                if (scrollTopAnchor === 0) {
                    scrollTopAnchor = elementOffset.top;
                } else {
                    scrollTopAnchor = scrollTopAnchor < elementOffset.top ? scrollTopAnchor : elementOffset.top;
                }

                let formedElement = {
                    element: clonedElement,
                    offset: {
                        left, top, width, height,
                    },
                    styles,
                };
                if (config?.parentClass) {
                    formedElement.parentClass = config.parentClass;
                }

                selectedElements.push(formedElement);
            });
        }

        let step = {
            selectedElements,
            message,
            callback,
        };
        commit(ON_BOARDING_CURRENT_STEP, step);

        commit(ON_BOARDING_LOAD, true);
    },
    enableOnBoarding({ state, commit, dispatch }, step) {
        state.onBoardingStepsToGo.push(step);
        commit('currentOnBoarding', 'billingModuleOnBoarding');
        dispatch('initOnBoarding');
    },
    restartOnBoarding({ state, commit, dispatch }, onboarding) {
        let index = state.onBoardingStepsToGo.indexOf(onboarding);
        if (index === -1) {
            state.onBoardingStepsToGo.push(onboarding);
        }
        commit('currentOnBoarding', onboarding);
        dispatch('initOnBoarding');
    },
    completeTour({ commit, dispatch }, tourName) {
        promisifiedAjax({
            url: '/admin/profile--make-tour-as-complete',
            data: {
                tour: tourName,
            },
            method: 'POST',
        });
        dispatch('closeOnBoarding');

        state.onBoardingStepsToGo = state.onBoardingStepsToGo.filter((item) => item !== tourName);
        commit('currentOnBoarding', '');
    },
    bodyScroll({ state }) {
        let body = document.querySelector('body');
        if (state.modalShow) {
            body.style.overflow = 'hidden';
        } else {
            body.removeAttribute('style');
        }
    },
    loadPermissions({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/on-boarding',
                method: 'GET',
                success: (response) => {
                    commit(SET_ON_BOARDING_PERMISSIONS, response.permissions);
                    resolve(response);
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const getters = {
    ...stateToGetters(state),
};

const mutations = {
    [ON_BOARDING_LOAD](state, modalShow) {
        state.modalShow = modalShow;
    },
    [ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD](state, pageFinanceDocumentsLoad) {
        state.pageFinanceDocumentsLoad = pageFinanceDocumentsLoad;
    },
    [ON_BOARDING_MAIN_DASHBOARD_LOAD](state, pageMainDashboardLoad) {
        state.pageMainDashboardLoad = pageMainDashboardLoad;
    },
    [ON_BOARDING_SCHEDULING_LOAD](state, pageSchedulingLoad) {
        state.pageSchedulingLoad = pageSchedulingLoad;
    },
    [ON_BOARDING_TASK_VIEW_LOAD](state, pageTaskViewLoad) {
        state.pageTaskViewLoad = pageTaskViewLoad;
    },
    [ON_BOARDING_NETWORK_SITE_VIEW_LOAD](state, pageNetworkSiteViewLoad) {
        state.pageNetworkSiteViewLoad = pageNetworkSiteViewLoad;
    },
    [ON_BOARDING_NETWORK_SITES_LIST_LOAD](state, pageNetworkSitesListLoad) {
        state.pageNetworkSitesListLoad = pageNetworkSitesListLoad;
    },
    [TASK_VIEW_ON_BOARDING_SHORT](state, taskViewOnBoardingShort) {
        state.taskViewOnBoardingShort = taskViewOnBoardingShort;
    },
    [ON_BOARDING_REFS](state, appRefs) {
        if (typeof state.appRefs === 'undefined') {
            state.appRefs = appRefs;
        } else {
            state.appRefs = Object.assign(appRefs, state.appRefs);
        }
    },
    [ON_BOARDING_STEPS](state, steps) {
        state.steps = steps;
    },
    [ON_BOARDING_CURRENT_STEP](state, currentStep) {
        state.currentStep = currentStep;
    },
    rememberedStep(state, rememberedStep) {
        state.rememberedStep = rememberedStep;
    },
    currentOnBoarding(state, currentOnBoarding) {
        state.currentOnBoarding = currentOnBoarding;
    },
    [SET_ON_BOARDING_PERMISSIONS](state, permissions) {
        state.permissionsMap = permissions;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
