import Message from '@/apps/admin/services/messengers/Message';
import TicketsService from '@/apps/admin/services/tickets/TicketsService';

export default {
    data() {
        return {
            massSelectionActive: false,
            selectedItems: [],
        };
    },
    methods: {
        // Mass Actions
        async massCreateTicketAndLinkToMessages() {
            await this.tryAddOrLinkTicketToMessages(this.openCreatingDialog);
        },
        async massLinkMessagesToTicket() {
            await this.tryAddOrLinkTicketToMessages(this.openLinkingDialog);
        },
        async tryAddOrLinkTicketToMessages(callback) {
            const ids = [...this.selectedItems];
            const withTickets = this.currentPart.messages.filter((message) => message.ticket.id && this.selectedItems.includes(message.id));

            if (withTickets.length) {
                this.afterUnlinking(ids, async () => {
                    await callback(ids);
                });
            } else {
                await callback(ids);
            }

            this.setMassActionSelectEnabled(false);
        },

        mergeMessageContents(ids) {
            let contents = [];
            for (let message of ids) {
                const item = this.currentPart.messages.find(({ id }) => id === message);
                const author = item.author?.name;
                if (ids.length === 1) {
                    contents.push(item.content ?? '');
                    continue;
                }
                contents.push(`${author}: \n${item.content ?? ''}`);
            }
            return contents.join('\n\n');
        },
        async linkTicketToMessages(ids, ticketId) {
            const service = new TicketsService(this.senderType);
            const { result, message } = await service.linkMessageToTicket(ids, ticketId);
            if (!result) {
                this.$showError(message, 4);
                return false;
            }
            this.$showSuccess(message, 4);
        },
        openLinkingDialog(ids) {
            showModal('messenger-link-ticket-to-message', {
                linked: {},
                customerId: this.customerId,
                messageId: ids,
                senderType: this.senderType,
                chatId: this.chatId,
            });
        },
        openCreatingDialog(ids) {
            showModal('messenger-create-ticket-from-message', {
                customerId: this.customerId,
                messenger: this.messenger,
                chatId: this.chatId,
                message: new Message({
                    content: this.mergeMessageContents(ids),
                }),
                onSubmit: async ({ ticketId }) => {
                    await this.linkTicketToMessages(ids, ticketId);
                },
            });
        },
        afterUnlinking(ids, action) {
            showModal('messenger-unlink-ticket-from-multiple-messages', {
                messages: this.currentPart.messages,
                selected: ids,
                unlinkCallback: async (args) => this.actionUnlinkTickets(args),
                onSubmit: action,
            });
        },
        async actionUnlinkTickets({ messageId, ticketId }) {
            const service = new TicketsService(this.senderType);
            const { result, message } = await service.unlinkMessageFromTicket(messageId, ticketId);
            if (!result) {
                this.$showError(message, 4);
                return false;
            }
            this.$showSuccess(message, 4);
        },

        // Common methods
        setMassActionSelectEnabled(isActive) {
            if (!isActive) {
                this.massDeselectAll();
            }
            this.massSelectionActive = isActive;
        },
        massDeselectAll() {
            this.selectedItems = [];
        },
        massSelectAll(listObjects) {
            this.selectedItems = [];
            let ids = [];
            if (listObjects instanceof Array) {
                ids = listObjects.map(({ id }) => id);
            }
            this.selectedItems.push(...ids);
        },
        massAddSelectedElement(id) {
            this.selectedItems.push(id);
        },
        massRemoveSelectedElement(id) {
            this.selectedItems = this.selectedItems.filter((item) => item !== id);
        },
        getMassActions(extended = true) {
            const selectedCount = this.$t('messengers', ': {count} selected messages', { count: this.selectedItems.length });
            const list = [
                {
                    selectedCount,
                    title: this.$t('messengers', 'Create ticket'),
                    name: 'mass-action-create-ticket',
                },
            ];
            if (extended) {
                list.unshift({
                    selectedCount,
                    title: this.$t('messengers', 'Link ticket'),
                    name: 'mass-action-link-ticket',
                });
            }
            return list;
        },
    },
};
